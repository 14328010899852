import { React, useState, useEffect } from 'react';
import axios from 'axios';

import './App.css';
import Station from './components/Station';

const STATIONS_URL = 'https://flood-monitoring-487a5-default-rtdb.firebaseio.com/stations.json';


const App = () => {
  
  const [stations, setStations] = useState([]);

  const getStations = async () => {
    let rsp;
    try {
      rsp = await axios.get(STATIONS_URL);
    } catch (err) {
      console.log("error fetching data...");
      return;
    }

    const stations = rsp.data;
    setStations(stations);
  };

  // On Start
  useEffect(() => {
    const getStationsStartup = async () => {
      let rsp;
      try {
        rsp = await axios.get(STATIONS_URL);
      } catch (err) {
        console.log("error fetching data...");
        return;
      }
  
      const stations = rsp.data;
      console.log(stations);
      setStations(stations);
    };
    getStationsStartup();
  }, [])
  
  const populateStations = (_stations) => {
    const stationsKeys = Object.keys(_stations);
    const stationsArray = [];
    for (let i = 0; i < stationsKeys.length; i++) {
      const _station = _stations[stationsKeys[i]];
      stationsArray.push(<Station key={stationsKeys[i]} id={stationsKeys[i]} name={_station.name} status={_station.status}/>);
    }
    return stationsArray;
  }
  
  return (
    <div className="App">
      { populateStations(stations) }
    </div>
  );
}

export default App;
