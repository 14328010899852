import React from 'react';

const Station = ({
    id,
    name,
    status
}) => {
    return (
        <div>
            <h1>Station</h1>
            <p>Station ID: { id }</p>
            <p>Name: { name }</p>
            <p>Status: { status }</p>
        </div>
    );
}

export default Station;